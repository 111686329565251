import React from "react";
import { TWITTER } from '../../config'

const NotFoundPage = () => {
  // useEffect(() => {
  //   navigate("/")
  // }, []);

  return (
    <div>
      <h1>The page you are looking for no longer exist</h1>
      <br />
      <p>If it was important, feel free to reach out <a href={TWITTER} target="_blank">@Twitter</a>. It still might be around!</p>
    </div>
  );
}

export default NotFoundPage